@import './variables';

.footer {
  width: 100%;
  background-color: $footer-background-colour;
  display: flex;
  align-items: center;
}

.footerLogoAndLinksDiv {
  align-items: center;
  display: flex;
}

.footerSocialMediaIconsDiv {
  justify-content: center;
  display: flex;
}

#footerDesktop {
  height: 80px;
  justify-content: space-between;
  margin-top: 150px;

  #footerLogoAndLinks {
    justify-content: center;

    img {
      margin-left: $desktop-left-right-margin;
    }
    ul {
      margin-left: 48px;
    }
  }

  #footerSocialMediaIcons {
    img {
      width: 24px;
      height: 24px;
    }
    ul {
      margin-left: 0;
      margin-right: $desktop-left-right-margin;
    }
    li {
      margin-left: 16px;
      margin-right: 0px;
    }
  }

  .footerLink {
    color: #ffffff;
  }
}

#footerMobile {
  height: 354px;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 80px;

  #footerLogoAndLinks {
    justify-content: center;
    flex-direction: column;
    width: 80%;
    .footerLink {
      color: #ffffff;
    }

    padding: 56px 106px 0px 106px;

    img {
      margin: 0 0 40px 0;
    }
    ul {
      margin: 0 auto;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
    li {
      text-align: center;
      margin: 0 0 32px 0;
      font-size: 12px;
    }
  }

  #footerSocialMediaIcons {
    width: 104px;
    margin: 8px 0 56px 0;

    ul {
      display: flex;

      margin: 0;
    }
    li {
      margin: 0 16px;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}

#footerTablet {
  height: 80px;
  justify-content: space-between;

  #footerLogoAndLinks {
    width: 100%;
    padding-left: 5%;
    ul {
      margin-left: 48px;
    }
    li {
      margin-left: 42px;
    }
  }

  #footerSocialMediaIcons {
    padding-right: 5%;

    img {
      width: 24px;
      height: 24px;
    }
    ul {
      margin-left: 0;
    }
    li {
      margin-left: 16px;
      margin-right: 0px;
    }
  }

  .footerLink {
    color: #ffffff;
  }
}
