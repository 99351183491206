@import './variables';

#projectDetailInfoContainer {
  display: grid;
  grid-template-columns: 40% 60%;
  margin: 115px 0 64px 0;
  height: 1192px;

  @include for-tablet-only {
    grid-template-columns: 100%;
    margin: 40px 0 80px 0;
    height: 100%;
  }

  @include for-phone-only {
    grid-template-columns: 100%;
    margin: 40px 0 80px 0;
    height: 100%;
  }
}

#individualProjectTextContainer {
  border-top: 1px solid rgba(51, 50, 61, 0.15);
  border-bottom: 1px solid rgba(51, 50, 61, 0.15);
  display: flex;
  flex-direction: column;
  margin: 0 125px 48px 0;
  padding: 48px 0;

  a {
    width: 202px;
  }

  @include for-tablet-only {
    margin: 0;
    padding: 32px 0;
  }

  @include for-phone-only {
    margin: 0;
    width: 100%;
    padding: 32px 0;
  }
}

article {
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: 400px;
    padding-top: 28px;

    @include for-tablet-only {
      height: 434px;
      padding-top: 31px;
    }

    @include for-phone-only {
      height: 197px;
      padding-top: 32px;
    }
  }
  h3 {
    font-size: 32px;
    font-family: $ibarra-real-nova-font-family;
    font-weight: 400;
  }
  p {
    margin-top: 28px;
    margin-bottom: 40px;
    font-family: $public-sans-font-family;
    font-size: 15px;
    color: #33323d;
    line-height: 30px;
    opacity: 0.8;
  }
}

#projectNavigator {
  margin: 64px 0 115px 0;
  width: 100%;
  height: 132px;
  display: flex;
  justify-content: space-between;

  align-items: center;
  border-top: 1px solid rgba(50, 51, 61, 0.15);
  border-bottom: 1px solid rgba(50, 51, 61, 0.15);

  .projectNavigatorOption {
    display: inline-block;
    align-items: center;

    @include for-phone-only {
      width: 100%;
    }
  }
  div.projectNavigatorOptionContainer:nth-of-type(1) {
    height: 100%;
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;

    @include for-phone-only {
      .arrowContainer {
        display: flex;
        justify-content: flex-start;
        height: 30px;
        margin-top: 24px;
      }
    }
  }
  div.projectNavigatorOptionContainer:nth-of-type(2) {
    border-left: 1px solid rgba(50, 51, 61, 0.15);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    align-items: center;

    a {
      justify-content: flex-end;
    }

    @include for-phone-only {
      justify-content: flex-start;
      a {
        justify-content: flex-start;
      }
      .arrowContainer {
        display: flex;
        justify-content: flex-end;
        height: 30px;
        margin-top: 24px;
      }
      .projectNavigatorOption {
        text-align: end;
      }
    }
  }
  div {
    display: inline-block;
  }
  img {
    width: 8px;
    height: 16px;
  }
  h3 {
    margin: 0 32px;
    font-family: $ibarra-real-nova-font-family;
    color: #33323d;
    font-size: 32px;
    font-weight: 400;
  }
  p {
    margin: 0 32px;
    font-family: $public-sans-font-family;
    font-size: 16px;
    line-height: 30px;
    color: #33323d;
    opacity: 0.5;
  }
  a {
    line-height: 30px;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
  }

  @include for-tablet-only {
    margin: 80px 0;
  }

  @include for-phone-only {
    margin: 64px 0;

    h3 {
      margin: 0;
    }
    p {
      margin: 0;
    }

    a {
      line-height: 30px;

      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
    .arrowContainer {
      width: 100%;
      margin-top: 24px;
    }
  }
}

#sidebar {
  @include for-tablet-only {
    display: flex;
  }
}

#projectOverviewNonDesktop {
  display: none;
  @include for-tablet-only {
    display: inline-block;
    width: 50%;
    padding-left: 12px;
    border-top: 1px solid rgba(51, 50, 61, 0.15);
    border-bottom: 1px solid rgba(51, 50, 61, 0.15);
  }
}

#main {
  @include for-tablet-only {
    margin-top: 40px;
  }
  @include for-phone-only {
    margin-top: 48px;
  }
}
