$public-sans-font-family: 'Public Sans', sans-serif;
$ibarra-real-nova-font-family: 'Ibarra Real Nova', serif;
$desktop-header-active-link-color: #5fb4a2;
$primary-button-background-color: #203a4c;
$modal-mobile-background-colour: #33323d;
$modal-mobile-font-colour: #ffffff;
$footer-background-colour: #33323d;
$desktop-left-right-margin: 165px;
$mobile-left-right-margin: 13px;
$project-tag-font-color: #5fb4a2;

@mixin for-phone-only {
  @media (max-width: 540px) {
    @content;
  }
}
@mixin for-tablet-only {
  @media (min-width: 541px) and (max-width: 992px) {
    @content;
  }
}
@mixin for-desktop-only {
  @media (min-width: 993px) {
    @content;
  }
}

@mixin for-desktop-monitor-only {
  @media (min-width: 1500px) {
    @content;
  }
}

@mixin primary-button-hover-state {
  background-color: #5fb4a2;
}
@mixin secondary-button-hover-state {
  background-color: #33323d;
  color: #ffffff;
}
