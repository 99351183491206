@import './variables';

#contactOverview {
  display: flex;

  @include for-tablet-only {
    flex-direction: column;
  }
  @include for-phone-only {
    flex-direction: column;
  }
}

#mainContact {
  width: 100%;
  margin-top: 48px;
  border-bottom: 1px solid rgba(51, 50, 61, 0.15);
  padding-bottom: 48px;

  li {
    margin: 0;
  }
  ul {
    margin-left: 0;
    margin-top: 24px;
    justify-content: start;
  }
  img {
    height: 24px;
    width: 24px;
    margin-right: 15px;
  }
  a {
    height: 24px;
  }
  p {
    font-family: $public-sans-font-family;
    font-size: 16px;
    color: #33323d;
    opacity: 0.8;
    line-height: 30px;
  }

  @include for-tablet-only {
    margin-top: 24px;
    padding-bottom: 32px;
  }
  @include for-phone-only {
    margin-top: 24px;
    padding-bottom: 32px;
  }
}

#sidebarContact {
  width: 100%;
  margin-top: 48px;
  border-bottom: 1px solid rgba(51, 50, 61, 0.15);
  h3 {
    font-family: $ibarra-real-nova-font-family;
    font-size: 40px;
    line-height: 42px;
    color: #33323d;
  }

  @include for-tablet-only {
    border-bottom: none;
    margin-top: 32px;
  }
  @include for-phone-only {
    border-bottom: none;
    margin-top: 24px;
  }
}
