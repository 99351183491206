@import './variables';

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerDesktop {
  margin: 40px $desktop-left-right-margin 40px $desktop-left-right-margin;
}
.headerTablet {
  width: 90%;
  margin: 40px auto 0 auto;
}
.headerMobile {
  width: 90vw;
  margin: 32px auto 0 auto;
}
img {
  width: 60px;
  height: 32px;
}
.hamburgerIcon {
  width: 24px;
  height: 13px;
}

ul {
  margin-left: 120px;
  display: flex;
  justify-content: flex-end;
}
li {
  margin: 0 43px;
  list-style-type: none;
}
li:nth-last-of-type(1) {
  margin: 0 0 0 43px;
}
a {
  text-decoration: none;
  color: #33323d;
  font-family: $public-sans-font-family;
  line-height: 14px;
}

#modalDiv {
  width: 223px;
  height: 186px;
  position: absolute;
  display: none;
  right: -70%;
  z-index: 1;
  background-color: $modal-mobile-background-colour;

  ul {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 223px;
    margin: 0;
    padding: 0;
    transform: translate(-50%, -50%);
  }
  li {
    margin: 0;
    font-size: 12px;
  }
  a {
    color: $modal-mobile-font-colour;
  }
}

.active {
  color: $desktop-header-active-link-color;
}

@keyframes slidein {
  from {
    right: -70%;
    width: 223px;
  }

  to {
    right: 32px;
  }
}

@keyframes slideout {
  from {
    right: 32px;
    width: 223px;
  }

  to {
    right: -70%;
  }
}

.modalSlideIn {
  animation-duration: 1s;
  animation-name: slidein;
  animation-fill-mode: forwards;
  display: block !important;
  right: 32px;
  top: 88px;
}

.modalSlideOut {
  animation-duration: 1s;
  animation-name: slideout;
  right: -70%;
  animation-fill-mode: forwards;
  top: 88px;
}
