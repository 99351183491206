@import './variables';

.bannerSectionDiv {
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.bannerSectionOverlayDiv {
  display: flex;
  margin-left: -1px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  font-family: $ibarra-real-nova-font-family;
}
.bannerSectionTitle {
  font-weight: 700;
}

#bannerSectionDesktop {
  height: 600px;
  margin: 65px $desktop-left-right-margin 0 $desktop-left-right-margin;
}

#bannerSectionTablet {
  height: 600px;
  margin: 65px 40px 0 40px;
}

#bannerSectionMobile {
  height: 311px;
  margin: 40px $mobile-left-right-margin 24px $mobile-left-right-margin;
}

#dropdownButtonDiv {
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
}

#bannerSectionOverlayDesktop {
  width: 445px;
  height: 357px;
  position: relative;
  top: 40.5%;
  h1 {
    padding-top: 56px;
    font-size: 50px;
    line-height: 50px;
  }
  a {
    position: absolute;
    bottom: 0;
  }
  button {
    display: flex;

    align-items: center;
    width: 200px;
    height: 48px;
    background-color: $primary-button-background-color;
    color: #ffffff;
    transition: background-color 0.6s;
  }
  button:hover {
    @include primary-button-hover-state;
  }
  img {
    padding-left: 16px;
    width: 16px;
    height: 12px;
    z-index: 100;
  }
  span {
    display: block;
    text-align: center;
    margin: 0 auto;
    letter-spacing: 2px;
    font-weight: 400;
  }
}

#bannerSectionOverlayMobile {
  height: 261px;
  h1 {
    margin: 0 $mobile-left-right-margin 0 $mobile-left-right-margin;
    padding-top: 24px;
    font-size: 40px;
    line-height: 42px;
  }
  a {
    position: relative;
    bottom: 0;
    width: 200px;
    left: 13;
  }
  button {
    display: flex;
    margin: 0 $mobile-left-right-margin;
    align-items: center;
    width: 200px;
    height: 48px;
    background-color: $primary-button-background-color;
    color: #ffffff;
  }
  img {
    padding-left: 16px;
    width: 16px;
    height: 12px;
  }
  span {
    display: block;
    text-align: center;
    margin: 0 auto;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 12px;
  }
}

button:hover {
  cursor: pointer;
}
