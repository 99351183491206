@import './variables';

.individualProjectTitle {
  font-family: $ibarra-real-nova-font-family;
  font-weight: 700;
  font-size: 40px;
  line-height: 42px;
}
.individualProjectOverview {
  font-family: $public-sans-font-family;
  font-size: 16px;
  line-height: 30px;
  color: #33323d;
  opacity: 0.8;
  padding-top: 28px;
  padding-bottom: 24px;

  @include for-tablet-only {
    padding-top: 32px;
    padding-bottom: 0px;
    font-size: 15px;
  }
  @include for-phone-only {
    padding-top: 32px;
    padding-bottom: 0px;
    font-size: 15px;
  }
}

.individualProjectTags {
  color: $project-tag-font-color;
  font-family: $public-sans-font-family;
  line-height: 30px;
  font-size: 16px;

  @include for-tablet-only {
    font-size: 13px;
    margin-top: 16px;
  }
  @include for-phone-only {
    font-size: 13px;
    margin-top: 16px;
  }
}

#projectTagsText {
  margin-bottom: 32px;
  margin-top: 16px;

  @include for-tablet-only {
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 16px;
  }

  @include for-phone-only {
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 16px;
  }
}

#individualProjectSectionDesktop:nth-of-type(even) {
  #individualProjectTextContainer {
    grid-column: 1;
    grid-row: 1;
  }
}

#individualProjectSectionDesktop {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  grid-auto-flow: dense;
  @include for-desktop-only {
    height: 500px;
  }
  @include for-desktop-monitor-only {
    height: 550px;
  }

  margin: 80px $desktop-left-right-margin;

  img {
    @include for-desktop-only {
      width: 540px;
    }
    @include for-desktop-monitor-only {
      width: 100%;
      max-height: 550px;
    }

    height: 100%;
  }
  a {
    width: 202px;
  }
  #individualProjectTextContainer {
    border-top: 1px solid rgba(51, 50, 61, 0.15);
    border-bottom: 1px solid rgba(51, 50, 61, 0.15);
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include for-desktop-only {
      margin: 0 115px;
    }
    @include for-desktop-monitor-only {
      margin: 0 115px;
    }

    p {
      width: 350px;
    }
    #projectTagsText {
      display: none;
    }
  }
}

#individualProjectSectionTablet:nth-of-type(even) {
  #individualProjectTextContainer {
    grid-column: 1;
    grid-row: 1;
  }
}

#individualProjectSectionTablet {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  height: 418px;
  margin: 80px 39px;

  img {
    width: 100%;
    height: 100%;
  }
  h2 {
    padding-top: 32px;
  }
  #individualProjectTextContainer {
    border-top: 1px solid rgba(51, 50, 61, 0.15);
    border-bottom: 1px solid rgba(51, 50, 61, 0.15);
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    margin-right: 60px;

    p {
      width: 100%;
      font-size: 15px;
      padding-bottom: 35px;
    }
  }
}

#individualProjectSectionMobile {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  height: 688px;
  width: 90%;
  margin: 40px auto;

  img {
    width: 100%;
    height: 100%;
  }
  h2 {
    padding-top: 24px;
  }
  #individualProjectTextContainer {
    border-top: 1px solid rgba(51, 50, 61, 0.15);
    border-bottom: 1px solid rgba(51, 50, 61, 0.15);
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    padding-bottom: 24px;

    p {
      width: 100%;
      font-size: 15px;
      padding-bottom: 24px;
      padding-top: 24px;
    }
    a {
      width: 175px;
    }
    button {
      width: 175px;
    }
  }
}
