@import './variables';

.aboutSection {
  height: 600px;
  display: grid;
}
.aboutSectionTitle {
  font-family: $ibarra-real-nova-font-family;
  font-weight: 700;
  font-size: 40px;
  line-height: 42px;
}
.aboutSectionContent {
  font-family: $public-sans-font-family;
  font-size: 16px;
  line-height: 30px;
  color: #33323d;
  padding-top: 28px;
  padding-bottom: 24px;
}
.aboutInfoContainerDiv {
  border-top: 1px solid rgba(51, 50, 61, 0.15);
  border-bottom: 1px solid rgba(51, 50, 61, 0.15);
  display: flex;
  flex-direction: column;
  @include for-phone-only {
    margin-top: 32px;
  }
}

.secondaryButton {
  width: 202px;
  height: 48px;
  color: #33323d;
  border: 1px solid #33323d;
  background-color: #ffffff;
  transition: all 0.6s;
}
.secondaryButton:hover {
  @include secondary-button-hover-state;
}

#aboutSectionDesktop {
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  margin: 150px $desktop-left-right-margin 0 $desktop-left-right-margin;

  img {
    width: 540px;
    height: 600px;
  }
  #aboutInfoContainer {
    height: 600px;
    margin: 0 auto;
    margin-bottom: 115px;
    @include for-desktop-only {
      width: 65%;
    }
    @include for-desktop-monitor-only {
      width: 80%;
    }
  }
  h2 {
    @include for-desktop-only {
      padding-top: 50px;
    }
    @include for-desktop-monitor-only {
      padding-top: 23%;
    }
  }

  a {
    width: 202px;
  }
}

#aboutSectionTablet {
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  width: 90%;
  margin: 96px auto 0 auto;

  img {
    width: 281px;
    height: 600px;
  }
  #aboutInfoContainer {
    height: 600px;
    margin: 0 auto;
  }
  h2 {
    padding-top: 50px;
  }

  a {
    width: 202px;
  }
}

.sectionMobile {
  height: 945px;
}

#aboutSectionMobile {
  object-fit: cover;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  margin: 96px $mobile-left-right-margin 0 $mobile-left-right-margin;

  img {
    display: block;
    height: 346px;
  }
  .smallMobileImage {
    width: 300px;
  }
  .normalMobileImage {
    width: 100%;
  }
  #aboutInfoContainer {
    padding-bottom: 50px;
    //margin-bottom: 65px;
  }
  h2 {
    margin-top: 32px;
  }
  p {
    width: 94%;
  }
  a {
    width: 202px;
  }
}
